<template>
  <div class="create-urgency">
    <UrgencyAppbar />
    <div class="ma-4">
      <UrgencyForm />
    </div>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Create Maintenance urgency
 * ==================================================================================
 **/

import UrgencyAppbar from '@/views/Home/Maintenance/Urgencies/components/UrgencyAppbar'
import UrgencyForm from '@/views/Home/Maintenance/Urgencies/components/UrgencyForm'

export default {
  components: {
    UrgencyAppbar,
    UrgencyForm,
  },
}
</script>
<style lang="scss">
.create-urgency {
  //
}
</style>
